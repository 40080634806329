import React, { useEffect, useState } from 'react';
import './App.css'; // Import the global styles
import VirtuOxDataGraph from './components/Data/VirtuOx/dataGraph';
import { useSelector } from 'react-redux';
import AnalyticsTable from './components/analytics_table/AnalyticsTable';
import * as AuthSelector from '../store/auth/selectors';
import { loggedInUser } from '../store/auth/selectors';
import Container from './components/container/Container';
import BarChartH from './components/barchart_horizontal/BarChartH';
import QualityLineChart from './components/LineGraph/QualityLineChart';
import QualityScoreTrend from './components/LineGraph/CustomLineGraph';
import DateRangePickerComp from '../views/modules/social/AuditSheet/DateRangePickerComp';
import { DateRangeFunction } from '../CommonLogic';
import * as moment from 'moment';
import { client } from '../services/apiService';
import _ from 'lodash'
import { Spinner } from 'react-bootstrap';

const QA = [
    "Total Audit Assigned",
    "Total Audits Pending",
    "Total Audits Accepted",
    "Total Rebuttals Pending",
    "Total Audits Pending to be Acknowledged",
    "Average Quality Score",
    "Auto Failed Calls",
    "% Auto Failed Calls",
    // "Average Evaluation Time",
    // "Average AHT",
    "Unique Agents Evaluated"
];

const Agent = [
    "Total Audits Evaluated",
    "Total Audits Accepted",
    "Total Rebuttals Pending",
    "Total Audits Pending to be Acknowledged",
    "Average Quality Score",
    "Auto Failed Calls",
    "% Auto Failed Calls",
    // "Average Evaluation Time",
    // "Average AHT"
];

const Admin = [
    "Total Audits Evaluated",
    "Total Audits Accepted",
    "Total Rebuttals Pending",
    "Total Audits Pending to be Acknowledged",
    "Average Quality Score",
    "Auto Failed Calls",
    "% Auto Failed Calls",
    // "Average Evaluation Time",
    // "Average AHT",
    "Unique Agents Evaluated"
];

const QAmanager = [
    "Total Audit Count",
    "Total Audits Pending",
    "Total Audits Accepted",
    "Total Rebuttals Pending",
    "Average Quality Score",
    "Auto Failed Calls",
    "% Auto Failed Calls",
    // "Average Evaluation Time",
    // "Average AHT (Calls Audited)",
    "Unique Agents Evaluated"
];

const QAkpiMetrics = ['Metrics', 'Self', 'Team', 'Process'];
const AgentkpiMetrics = ['Metrics', 'Self', 'Team QA', 'Process'];
const ManagerkpiMetrics = ['Metrics', 'Team', 'Process'];

function DemoChart() {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const userInfo = useSelector(loggedInUser);
    const [auditMetrics, setAuditMetrics] = useState([]);
    const [auditMetricsData, setAuditMetricsData] = useState({});
    const [kpiMetrics, setKpiMetrics] = useState()
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        handleDateChange(DateRangeFunction(0, 6))
        if (userInfo.role == "QA") {
            setAuditMetrics(QA)
            setKpiMetrics(QAkpiMetrics)
        } else if (userInfo.role == "Agent") {
            setAuditMetrics(Agent)
            setKpiMetrics(AgentkpiMetrics)
        }
        else if (userInfo.role == "Admin" || userInfo.role == "Manager") {
            setAuditMetrics(Admin)
            setKpiMetrics(ManagerkpiMetrics)
        }
        else if (userInfo.role == "QA-Manager") {
            setAuditMetrics(QAmanager)
            setKpiMetrics(ManagerkpiMetrics)
        }
    }, [userInfo])

    const {
        other_performance_metrics,
        other_performance_metrics_agent,
        other_performance_metrics_tl,
        other_performance_metrics_qamanager,
        qa_wise_average_audit_score,
        qa_wise_average_audit_score_tl,
        surging_topic,
    } = VirtuOxDataGraph

    const timeWiseAuditData = [
        { Time_Period: '2024-09-01', NumberofAudits: 85 },
        { Time_Period: '2024-09-02', NumberofAudits: 90 },
        { Time_Period: '2024-09-03', NumberofAudits: 75 },

        { Time_Period: '2024-09-23', NumberofAudits: 56 },
        { Time_Period: '2024-09-10', NumberofAudits: 67 },
        { Time_Period: '2024-09-14', NumberofAudits: 87 },
        { Time_Period: '2024-09-20', NumberofAudits: 95 },
        { Time_Period: '2024-09-09', NumberofAudits: 67 },
        { Time_Period: '2024-09-21', NumberofAudits: 98 },
        { Time_Period: '2024-09-29', NumberofAudits: 54 },
    ];

    const TimewiseFatalAuditsTrend = [
        { Time_Period: '2024-09-01', NumberofAudits: 85 },
        { Time_Period: '2024-09-02', NumberofAudits: 90 },
        { Time_Period: '2024-09-03', NumberofAudits: 75 },
        { Time_Period: '2024-09-23', NumberofAudits: 56 },
        { Time_Period: '2024-09-10', NumberofAudits: 67 },
        { Time_Period: '2024-09-14', NumberofAudits: 87 },
        { Time_Period: '2024-09-20', NumberofAudits: 95 },
        { Time_Period: '2024-09-09', NumberofAudits: 67 },
        { Time_Period: '2024-09-21', NumberofAudits: 98 },
        { Time_Period: '2024-09-29', NumberofAudits: 54 },
    ];
    const [timeWiseAudit, setTimeWiseAudit] = useState(timeWiseAuditData);
    const [timeWiseAuditFetal, setTimeWiseAuditFetal] = useState(TimewiseFatalAuditsTrend);

    async function handleDateChange(range) {
        setLoader(true);
        const { startDate, endDate } = range;
        const start = moment(startDate).toISOString();
        const end = moment(endDate).toISOString();
        const params = `organisationId={{org-id}}&startDate=${start}&endDate=${end}&offset=${new Date().getTimezoneOffset()}`

        if (userInfo.role == "QA") {
            const { data } = await client.get(`/qa-dashboard-info?${params}`);
            setAuditMetricsData(data);
        }
        if (userInfo.role == "Agent") {
            const { data } = await client.get(`/agent-dashboard-info?${params}`);
            setAuditMetricsData(data);
        }
        if (userInfo.role == "Admin" || userInfo.role == "Manager") {
            const { data } = await client.get(`/agentmanager-dashboard-info?${params}`);
            setAuditMetricsData(data);
        }
        if (userInfo.role == "QA-Manager") {
            const { data } = await client.get(`/qamanager-dashboard-info?${params}`);
            setAuditMetricsData(data);
        }
        setLoader(false);
    }

    return (
        <div className='page-wrapper no_overflow_x '>
            <div className='content pb-0'>
                <div className='row'>
                    <div className='app-container-demo'>
                        <div className='dashboard'>
                            <div
                                className='large-card title_legend_line quality_score_graph_only'
                                style={{ width: '30%' }}>
                                <div className='card-title'>
                                    <h6>Date Range Selector</h6>
                                </div>
                                <div className='card-title'>
                                    <DateRangePickerComp
                                        defaultRange={DateRangeFunction(0, 6)}
                                        onDateChange={handleDateChange}
                                    />
                                </div>
                            </div>
                            {/* //loader */}
                            {loader && <div className='loader_comp'>
                                <Spinner animation="border" variant="light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            }

                            <div className={loader ? 'loader_blur_area' : ''}>
                                <div className='small-cards'>
                                    {
                                        auditMetrics.map((el, idx) => {
                                            return (
                                                <div className='small-card'>
                                                    <div className='dashcard-container'>
                                                        <div className='card-body-demo-demo'>
                                                            <div className='scalar-title-container'>
                                                                <h3 className='scalar-title'>
                                                                    {el}
                                                                </h3>
                                                                <h4 className='scalar-value'>
                                                                    {_.get(auditMetricsData, `cardInformation[${el}]`, "00")}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className='large-cards mt-3'>

                                    <div className='large-card graph_tbl'>
                                        <AnalyticsTable
                                            header="Key Performance Indicator"
                                            thead={kpiMetrics}
                                            data={_.get(auditMetricsData, "keyPerformanceIndicator", [])}
                                            keys={["metrics", "team", "process"]}
                                        />
                                    </div>
                                    <div className='large-card title_legend_line quality_score_graph_only'>
                                        <div className='card-title'>
                                            <h6>Quality Score Trend</h6>
                                        </div>
                                        <QualityScoreTrend data={_.get(auditMetricsData, "qualityScoreTrend") ?? []} />
                                    </div>

                                    {false && <div className='large-card'>
                                        <AnalyticsTable
                                            header={'Other Performance Metrics'}
                                            thead={
                                                userInfo.orgRole === 'Team Leader'
                                                    ? other_performance_metrics_tl?.legends
                                                    : userInfo.orgRole ===
                                                        'Customer Service Associate'
                                                        ? other_performance_metrics_agent?.legends
                                                        : userInfo.orgRole ===
                                                            'QA Manager'
                                                            ? other_performance_metrics_qamanager?.legends
                                                            : other_performance_metrics?.legends
                                            }
                                            data={
                                                userInfo.orgRole === 'Team Leader'
                                                    ? other_performance_metrics_tl?.data
                                                    : userInfo.orgRole ===
                                                        'Customer Service Associate'
                                                        ? other_performance_metrics_agent?.data
                                                        : userInfo.orgRole ===
                                                            'QA Manager'
                                                            ? other_performance_metrics_qamanager?.data
                                                            : other_performance_metrics?.data
                                            }
                                            keys={[]}
                                        />
                                    </div>}

                                    {false && <div className='card_title_only'>
                                        <Container
                                            title={'Surging Topics'}
                                            body={
                                                <BarChartH
                                                    paddLeft={'16px'}
                                                    data={surging_topic}
                                                    height={400}
                                                />
                                            }
                                        />
                                    </div>}

                                    {['Admin', 'Manager'].includes(userInfo.role) && (
                                        <div className='large-card'>
                                            <AnalyticsTable
                                                header={'Agent Leaderboard'}
                                                thead={[
                                                    "Agent Name",
                                                    "Quality Score",
                                                    "Number of Evaluations",
                                                    "Number of Fatal Evaluations"
                                                ]}
                                                data={_.get(auditMetricsData, 'agentLeaderboard', [])}
                                                keys={["name", "averageAuditScore", "noOfAudits", "noOfFatalAudits"]}
                                            />
                                        </div>
                                    )}

                                    {['Admin', 'Manager'].includes(userInfo.role) && (
                                        <div className='large-card'>
                                            <AnalyticsTable
                                                header={'QA Leaderboard'}
                                                thead={[
                                                    "QA Name",
                                                    "Quality Score",
                                                    "Number of Evaluations",
                                                    "Number of Fatal Evaluations"
                                                ]}
                                                data={_.get(auditMetricsData, 'qaLeaderboard', [])}
                                                keys={["name", "averageAuditScore", "noOfAudits", "noOfFatalAudits"]}
                                            />
                                        </div>
                                    )}

                                    {false && (
                                        <div className='large-card'>
                                            <AnalyticsTable
                                                header={
                                                    'QA wise Average Audit Score '
                                                }
                                                thead={
                                                    userInfo.orgRole ===
                                                        'Team Leader'
                                                        ? qa_wise_average_audit_score_tl?.legends
                                                        : qa_wise_average_audit_score?.legends
                                                }
                                                data={
                                                    userInfo.orgRole ===
                                                        'Team Leader'
                                                        ? qa_wise_average_audit_score_tl?.data
                                                        : qa_wise_average_audit_score?.data
                                                }
                                                keys={[]}
                                            />
                                        </div>
                                    )}

                                    {false && (
                                        <div className='large-card'>
                                            <div className='card-title'>
                                                Time wise Audit Trend
                                            </div>
                                            <QualityLineChart
                                                data={timeWiseAudit}
                                                chartFor='Time wise Audit Trend'
                                            />
                                        </div>
                                    )}

                                    {false && (
                                        <div className='large-card'>
                                            <div className='card-title'>
                                                Time wise Fatal Audits Trend
                                            </div>
                                            <QualityLineChart
                                                data={timeWiseAuditFetal}
                                                chartFor='Time wise Audit Trend'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemoChart;

function transformAdminData(data) {
    return data.map(item => ({
        agent_name: item["User Name"],
        quality_score: `${item["Quality Score"]}%`,
        number_of_evaluations: `${item["Number of Evaluations"]}`,
        number_of_fatal_evaluations: `${item["Number of Fatal Evaluations"]}`,
    }));
}