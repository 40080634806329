import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import CustomLegend from './CustomLegend';
import * as moment from 'moment';

// Custom Tooltip component
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label}`}</p>
                <p className="desc">{`Avg. Quality Score: ${((+payload[0].payload.Team + +payload[0].payload.Process) / 2).toFixed(2)}%`}</p>
                <p className="desc">{`Count of Audits: ${payload[0].payload.countOfAudit}`}</p>
            </div>
        );
    }
    return <div className="custom-tooltip">No data</div>;
};

const QualityScoreTrend = ({ data }) => {
    const hasData = data && data.length > 0;
    const placeholderData = [
        { auditDate: moment().format('YYYY-MM-DD'), Team: 0, Process: 0, countOfAudit: 0 }
    ];

    return (
        <ResponsiveContainer width="100%" height={380} >
            <LineChart data={hasData ? data : placeholderData} margin={{ top: 30, right: 30, left: 20, bottom: 20 }}>
                <XAxis dataKey="auditDate" label={{value : ' Time Interval', position: 'insideBottom', offset: -15,  }} >
                </XAxis>
                <YAxis>
                    <Label angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} >
                        Avg. Quality Score
                    </Label>
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" align="center" height={36} content={<CustomLegend />} />
                 {/* <Line type="monotone" dataKey="myPerformance" name="My Performance" stroke=/> */}
                <Line type="monotone" dataKey="Team"  stroke="#23CE6B" />
                <Line type="monotone" dataKey="Process" stroke="#05603A" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default QualityScoreTrend;
